import { find, orderBy } from 'lodash';

import { Registration, Slide } from 'interfaces/slide';
import affineTransform, { Point } from 'utils/slideTransformation';

export const findRegistrationForSlides = (
  slides: Pick<Slide, 'id' | 'registrations'>[]
): {
  firstSlideRegistration: Registration | undefined;
  secondSlideRegistration: Registration | undefined;
} => {
  const slide0Registrations = orderBy(slides?.[0]?.registrations, ['approvedDate'], ['desc']);
  const slide1Registrations = orderBy(slides?.[1]?.registrations, ['approvedDate'], ['desc']);

  // Try to find a registration between the two slides - prefer latest approved registrations
  const firstSlideApprovedRegistration = find(
    slide0Registrations,
    ({ registrationSlideId, approved }) => registrationSlideId === slides?.[1]?.id && approved
  );
  const secondSlideApprovedRegistration = find(
    slide1Registrations,
    ({ registrationSlideId, approved }) => registrationSlideId === slides?.[0]?.id && approved
  );
  const firstSlideRegistration =
    firstSlideApprovedRegistration ||
    (!secondSlideApprovedRegistration &&
      find(slide0Registrations, ({ registrationSlideId }) => registrationSlideId === slides?.[1]?.id));
  const secondSlideRegistration =
    secondSlideApprovedRegistration ||
    (!firstSlideApprovedRegistration &&
      find(slide1Registrations, ({ registrationSlideId }) => registrationSlideId === slides?.[0]?.id));

  return { firstSlideRegistration, secondSlideRegistration };
};

export const calculateRegistrationAndAngles = (slides: Pick<Slide, 'id' | 'registrations'>[]) => {
  let registeredFromSlide: 0 | 1 = 0;
  let registration: Registration | null = null;

  const { firstSlideRegistration, secondSlideRegistration } = findRegistrationForSlides(slides);
  if (firstSlideRegistration) {
    registeredFromSlide = 0;
    registration = firstSlideRegistration;
  } else if (secondSlideRegistration) {
    registeredFromSlide = 1;
    registration = secondSlideRegistration;
  } else if ((slides?.length ?? 0) > 1 && slides[0]?.id && slides[0].id === slides[1]?.id) {
    registeredFromSlide = 0;
    registration = {
      slideId: slides[0].id,
      registrationSlideId: slides[0].id,
      angle: 0,
      zoomRatio: 1,
      points1: [],
      points2: [],
      approved: true,
    };
  } else {
    registration = null;
  }
  return { registration, registeredFromSlide };
};

export const applyRegistrationAffineTransformToPoint = ({
  isViewerRegisteredViewer,
  registration,
  point,
  slideRegistrationPoints1,
  slideRegistrationPoints2,
}: {
  isViewerRegisteredViewer: boolean;
  registration: Registration;
  point: Point;
  slideRegistrationPoints1: Point[];
  slideRegistrationPoints2: Point[];
}): Point => {
  if (isViewerRegisteredViewer) {
    const angle = registration.angle || 0;
    return affineTransform(angle, slideRegistrationPoints1, slideRegistrationPoints2, point);
  } else {
    const angle = -(registration.angle || 0);
    return affineTransform(angle, slideRegistrationPoints2, slideRegistrationPoints1, point);
  }
};

// This replaces the calculation of the zoom ratio between two slides done by algo ops - since DeckGL's zoom is only dependent on the resolution of the slide,
// not the size of the slide, we can just use the ratio of the resolutions.
// Previous calculation (in the registration entry): (registeredToSlide.sizeCols * registeredToSlide.maxResolution) / (registeredFromSlide.sizeCols * registeredFromSlide.maxResolution)
export const getSlidesZoomRatio = (
  registeredFromSlide: Pick<Slide, 'maxResolution' | 'sizeCols'>,
  registeredToSlide: Pick<Slide, 'maxResolution' | 'sizeCols'>
) => registeredToSlide.maxResolution / registeredFromSlide.maxResolution;
