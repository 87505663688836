import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid';
import { Registration } from 'interfaces/slide';
import { compact, filter, find, first, includes, map, size, sortBy } from 'lodash';
import React from 'react';
import { RegistrationDetails } from '../..';

interface RegistrationOrchestrationProps {
  studyId: string;
  orchestration: string;
  registrations: Registration[];
  selectedRegistrations: RegistrationDetails[];
  onSelectSlidesRegistration: (value: RegistrationDetails[]) => void;
}

const SLIDE_REGISTRATION_PAGE_SIZE = 10;
const SLIDE_REGISTRATION_TABLE_HEIGHT = 454; // the height of the table with 10 rows in compact density

const getRowId = (row: Registration) => {
  return `${row.slideId}-${row.registrationSlideId}`;
};

const RegistrationOrchestration: React.FC<RegistrationOrchestrationProps> = ({
  studyId,
  orchestration,
  registrations,
  selectedRegistrations,
  onSelectSlidesRegistration,
}) => {
  const apiRef = useGridApiRef();
  const selectedRowIds: GridRowSelectionModel = map(
    selectedRegistrations,
    (registration) => `${registration.source_slide_id}-${registration.target_slide_id}`
  );

  const columns: GridColDef[] = [
    { field: 'slideId', headerName: 'Source Slide ID', width: 150 },
    {
      field: 'registrationSlideId',
      headerName: 'Target Slide ID',
      width: 200,
      valueGetter: (params) => params.row.registrationSlideId,
    },
    {
      field: 'approved',
      headerName: 'Approved',
      width: 100,
      valueGetter: (params) => (params.row.approved ? 'Yes' : 'No'),
    },
  ];

  const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    const addedRowIds = filter(newRowSelectionModel, (id) => !includes(selectedRowIds, id));
    let newSelectedRegistrations: RegistrationDetails[] = [];

    // there are 2 options for selection model to change: a single row was clicked, or all rows checkbox was clicked
    if (size(addedRowIds) === 1) {
      const addedRow = apiRef.current?.getRow(first(addedRowIds));

      // remove the other selected row for this pair of slides
      const rowIdToRemove = find(selectedRowIds, (id) => {
        const currentRow = apiRef.current?.getRow(id);
        if (
          (currentRow?.slideId === addedRow?.slideId &&
            currentRow?.registrationSlideId === addedRow?.registrationSlideId) ||
          (currentRow?.slideId === addedRow?.registrationSlideId &&
            currentRow?.registrationSlideId === addedRow?.slideId)
        ) {
          return true;
        }
      });

      const newSelectionModalWithRemovedRowIds = filter(newRowSelectionModel, (id) => id !== rowIdToRemove);
      newSelectedRegistrations = compact(
        map(newSelectionModalWithRemovedRowIds, (id) => {
          const row = apiRef.current?.getRow(id);
          return getRegistrationDetails(row, studyId);
        })
      );
    } else {
      const newSelectionRows = map(newRowSelectionModel, (id) => apiRef.current?.getRow(id));
      const newCorrectedSelectionRows = getMostRelevantRegistrationPerPair(newSelectionRows);
      newSelectedRegistrations = compact(
        map(newCorrectedSelectionRows, (row) => {
          return getRegistrationDetails(row, studyId);
        })
      );
    }

    onSelectSlidesRegistration(newSelectedRegistrations);
  };

  const autoHeight = (registrations ? registrations.length : 0) < SLIDE_REGISTRATION_PAGE_SIZE;

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{orchestration}</AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={1}
          sx={{ width: '100%', height: autoHeight ? undefined : SLIDE_REGISTRATION_TABLE_HEIGHT }}
        >
          <DataGrid
            apiRef={apiRef}
            rows={registrations}
            columns={columns}
            getRowId={getRowId}
            checkboxSelection
            rowSelectionModel={selectedRowIds}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            slotProps={{
              pagination: {
                SelectProps: {
                  sx: { width: 'auto' },
                },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: SLIDE_REGISTRATION_PAGE_SIZE,
                },
              },
            }}
            pageSizeOptions={[SLIDE_REGISTRATION_PAGE_SIZE]}
            autoHeight={autoHeight}
            density="compact"
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const getMostRelevantRegistrationPerPair = (registrations: Registration[]): Registration[] => {
  const seen = new Map<string, Registration>();
  const sortedRegistrations = sortBy(registrations, ['approved', 'createdAt'], ['desc', 'desc']);

  for (const registration of sortedRegistrations) {
    // Sort slideId and registrationSlideId to normalize the key
    const key = [registration.slideId, registration.registrationSlideId].sort().join('-');

    if (!seen.has(key)) {
      seen.set(key, registration); // Store the first approved item for this (slideId, registrationSlideId) pair
    }
  }

  return Array.from(seen.values());
};

const getArtifactUrl = (registration: Registration, studyId: string): string => {
  return (
    registration.artifactUrl ??
    `artifact://studies/${registration.studyId ?? studyId}/${registration.slideId}_to_${
      registration.registrationSlideId
    }.slides_registration.slides_registration.ckpt?meta.orchestration_id=${registration.orchestrationId}`
  );
};

export const getRegistrationDetails = (registration: Registration, studyId: string): RegistrationDetails => {
  return {
    source_slide_id: registration.slideId,
    target_slide_id: registration.registrationSlideId,
    artifact_url: getArtifactUrl(registration, studyId),
  };
};

export default RegistrationOrchestration;
