import { Grid, Grow, Paper } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import classnames from 'classnames';
import { every, forEach, isEmpty, isEqual, map } from 'lodash';
import OpenSeadragon from 'openseadragon';
import React from 'react';

import { ColorModeContext } from 'components/ColorModeContext';
import { BaseSlideVisualSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ColorMapLegend, { getHeatmapLegends } from 'components/Procedure/SlidesViewer/ColorMapLegend';
import {
  applyRegistrationAffineTransformToPoint,
  findRegistrationForSlides,
} from 'components/Procedure/SlidesViewer/registration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Registration, Slide } from 'interfaces/slide';
import { Point } from 'utils/slideTransformation';
import Overlays from '../Overlays';
import PinCommentsHandler from '../PinComment/PinCommentsHandler';
import MousePositionOverlay, { MouseOverlayLocation } from './MousePositionOverlay';
import ScaleIndicator from './ScaleIndicator';
import Viewer from './Viewer';

import './SlidesViewer.scss';

interface State {
  registrationPoints1: Point[];
  registrationPoints2: Point[];
}

interface Props {
  slides: SlideWithChannelAndResults[];
  slide1BaseSlideVisualSettings: BaseSlideVisualSettings;
  slide2BaseSlideVisualSettings: BaseSlideVisualSettings;
  procedureId: number;
  showNavigation: boolean;
  magnificationValue: number;
  updateMagnificationFromZoom: (zoom: number) => void;
  zoomFactor: number;
  displaySlideId: boolean;
  unmountViewportBounds: (center: Point, zoom: number) => void;
  fieldOfView: { center: Point; zoom: number };
  hideComments?: boolean;
  focusedSlideId?: string;
}

class SlidesViewer extends React.Component<Props, State> {
  viewer1: Viewer;

  viewer1Initialized: boolean;

  viewer1Angle: number;

  viewer1Leading: boolean;

  viewer2: Viewer;

  viewer2Initialized: boolean;

  viewer2Angle: number;

  viewer2Leading: boolean;

  registeredFromSlide: number;

  registration: Registration;

  _lastUpdatedMagnification: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      registrationPoints1: [],
      registrationPoints2: [],
    };
  }

  setupRegistration(slides: Slide[]) {
    const numSlides = slides?.length ?? 0;
    if (numSlides != 2) {
      throw new Error(`Can only setup registration for 2 slides, but got ${numSlides} slides`);
    }

    const slide1 = slides[0];
    const slide2 = slides[1];

    this.viewer1Angle = 0;
    this.viewer2Angle = 0;

    const { firstSlideRegistration, secondSlideRegistration } = findRegistrationForSlides(slides);

    if (firstSlideRegistration) {
      this.registeredFromSlide = 0;
      this.registration = firstSlideRegistration;
      this.viewer2Angle = -this.registration.angle;
    } else if (secondSlideRegistration) {
      this.registeredFromSlide = 1;
      this.registration = secondSlideRegistration;
      this.viewer2Angle = this.registration.angle;
    } else if (slide1.id === slide2.id) {
      this.registeredFromSlide = 0;
      this.registration = {
        slideId: slide1.id,
        registrationSlideId: slide1.id,
        angle: 0,
        zoomRatio: 1,
        points1: [],
        points2: [],
        approved: true,
      };
    } else {
      this.registration = null;
    }
    if (this.viewer1Initialized) {
      this.viewer1?.osd?.setRotation(this.viewer1Angle);
    }
    if (this.viewer2Initialized) {
      this.viewer2?.osd?.setRotation(this.viewer2Angle);
    }
  }

  componentDidMount() {
    const { slides } = this.props;
    const numSlides = slides?.length ?? 0;
    if (numSlides < 1 || numSlides > 2) {
      throw new Error('SlidesViewer can only accept 1 or 2 slides');
    }

    const slide1 = slides[0];
    const slide2 = slides[1];

    const viewer1Opacity = (this.props.slide1BaseSlideVisualSettings?.opacity ?? 100) / 100;

    this.viewer1.init('navigator1', viewer1Opacity, this.viewer1Angle ?? 0, isEmpty(slide1?.channelsMetadata));
    this.updateScalebar(this.viewer1, slide1.maxResolution);
    this.viewer1Initialized = true;

    if (numSlides === 2) {
      this.setupRegistration(slides);

      const viewer2Opacity = (this.props.slide2BaseSlideVisualSettings?.opacity ?? 100) / 100;

      this.viewer2.init('navigator2', viewer2Opacity, this.viewer2Angle ?? 0, isEmpty(slide2?.channelsMetadata));

      this.updateScalebar(this.viewer2, slide2.maxResolution);
      this.viewer2Initialized = true;
    }
    this.forceUpdate();
  }

  private updateScalebar = (viewer: Viewer, maxRes: number) => {
    if (!viewer) {
      return;
    }
    // @ts-ignore
    const scalebarInstance = viewer.osd?.internalOSD.scalebarInstance;
    if (!scalebarInstance) {
      return;
    }
    scalebarInstance.updateOptions({
      pixelsPerMeter: 1000000 / maxRes,
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this._lastUpdatedMagnification !== this.props.magnificationValue ||
      prevProps.zoomFactor !== this.props.zoomFactor
    ) {
      this._lastUpdatedMagnification = this.props.magnificationValue;
      const newZoomLevel = (this.props.magnificationValue ?? 1) / this.props.zoomFactor;
      this.changeToPresetZoom(newZoomLevel);
    }

    forEach(this.props.slides, (slide, slideIndex) => {
      const viewer = slideIndex === 0 ? this.viewer1 : this.viewer2;
      const opacity =
        slideIndex === 0
          ? this.props.slide1BaseSlideVisualSettings?.opacity / 100
          : this.props.slide2BaseSlideVisualSettings?.opacity / 100;
      if (!viewer || !isEmpty(this.props.slides[slideIndex]?.channelsMetadata)) return;
      this.changeSlideOpacity(opacity, viewer);
    });
    if (!isEqual(prevProps.slides, this.props.slides) && this.props.slides?.length === 2) {
      this.setupRegistration(this.props.slides);

      if (this.viewer1Initialized && this.viewer2Initialized) {
        this.handleLeadingViewerFieldOfViewChange(this.viewer1);
        this.handleLeadingViewerFieldOfViewChange(this.viewer2);
      }
    }

    const slide1 = this.props.slides?.[0];
    const slide2 = this.props.slides?.[1];

    if (prevProps.slides?.[0]?.maxResolution !== slide1?.maxResolution) {
      this.updateScalebar(this.viewer1, slide1?.maxResolution);
    }
    if (prevProps.slides?.[1]?.maxResolution !== slide2?.maxResolution) {
      this.updateScalebar(this.viewer2, slide2?.maxResolution);
    }
  }

  componentWillUnmount() {
    if (this.viewer1) {
      if (isEqual(this.viewer1.getCenter(), this.viewer1.viewportToImageCoordinates(this.viewer1.getCenter()))) {
        // This is a case when the viewer didn't load the image.
        // Happens when opening /procedure/:id without params (for example from dashboard)
        return;
      }

      this.props.unmountViewportBounds(
        this.viewer1.viewportToImageCoordinates(this.viewer1.getCenter()),
        this.viewer1.getZoom()
      );
    }
  }

  changeToPresetZoom = (zoomLevel: number) => {
    if (this.viewer1) {
      this.viewer1.zoomTo(zoomLevel);
    }
    if (this.viewer2) {
      this.viewer2.zoomTo(zoomLevel);
    }
  };

  changeSlideOpacity = (opacity: number, viewer: Viewer) => {
    const internalViewer = viewer?.osd?.internalOSD;
    if (!internalViewer) return;
    const tiledImage = internalViewer.world.getItemAt(0);
    if (!tiledImage) return;
    tiledImage.setOpacity(opacity);
    internalViewer.forceRedraw();
  };

  preserveFieldOfView = () => {
    if (!this.props.fieldOfView) {
      return;
    }
    const { center, zoom } = this.props.fieldOfView;
    if (!this.viewer1?.imageToViewportPoint(center)) {
      // This case is when the viewer didn't load properly
      return;
    }

    this.viewer1.panTo(this.viewer1.imageToViewportPoint(center));
    this.viewer1.zoomTo(zoom);
  };

  handleViewer1ZoomSlide = (zoomObj: { zoom: number }) => {
    if (this.viewer2Leading) return;

    this.handleLeadingViewerFieldOfViewChange(this.viewer1, zoomObj.zoom);
  };

  handleViewer2ZoomSlide = (zoomObj: { zoom: number }) => {
    if (this.viewer1Leading) return;

    this.handleLeadingViewerFieldOfViewChange(this.viewer2, zoomObj.zoom);
  };

  handleViewer1PanSlide = () => {
    if (this.viewer2Leading) return;

    this.handleLeadingViewerFieldOfViewChange(this.viewer1);
  };

  handleViewer2PanSlide = () => {
    if (this.viewer1Leading) return;

    this.handleLeadingViewerFieldOfViewChange(this.viewer2);
  };

  get registeredViewer() {
    return this.registeredFromSlide === 0 ? this.viewer1 : this.viewer2;
  }

  get nonRegisteredViewer() {
    return this.registeredViewer === this.viewer1 ? this.viewer2 : this.viewer1;
  }

  updateRegistrationPoints1() {
    this.setState({
      registrationPoints1: this.registration.points1.map((point: Point) =>
        this.registeredViewer.imageToViewportPoint(point)
      ),
    });
  }

  updateRegistrationPoints2() {
    this.setState({
      registrationPoints2: this.registration.points2.map((point: Point) =>
        this.nonRegisteredViewer.imageToViewportPoint(point)
      ),
    });
  }

  handleLeadingViewerFieldOfViewChange = (leadingViewer: Viewer, zoom?: number) => {
    // it seems that in some cases this parameter ends up as null
    if (!leadingViewer) return;

    const otherViewer = leadingViewer === this.viewer1 ? this.viewer2 : this.viewer1;
    if (this.registration) {
      if (leadingViewer === this.viewer1) {
        this.viewer1Leading = true;
      } else {
        this.viewer2Leading = true;
      }

      if (zoom !== undefined) {
        const zoomRatio = this.registration.zoomRatio;
        const newZoomLevel = leadingViewer === this.registeredViewer ? zoom * zoomRatio : zoom / zoomRatio;
        otherViewer.zoomTo(newZoomLevel);
        this.updateRegistrationPoints1();
        this.updateRegistrationPoints2();
      }
      const transformedPoint = applyRegistrationAffineTransformToPoint({
        isViewerRegisteredViewer: leadingViewer === this.registeredViewer,
        registration: this.registration,
        point: leadingViewer.getCenter(),
        slideRegistrationPoints1: this.state.registrationPoints1,
        slideRegistrationPoints2: this.state.registrationPoints2,
      });

      otherViewer.panTo(new OpenSeadragon.Point(transformedPoint.x, transformedPoint.y));

      if (leadingViewer === this.viewer1) {
        this.viewer1Leading = false;
      } else {
        this.viewer2Leading = false;
      }
    }

    if (zoom !== undefined) {
      const newMagnification = zoom * this.props.zoomFactor;
      this._lastUpdatedMagnification = newMagnification;
      this.props.updateMagnificationFromZoom(newMagnification);
    }
  };

  render() {
    const { slides, displaySlideId, showNavigation, focusedSlideId } = this.props;
    const areAllSlidesFocused = every(slides, { id: focusedSlideId });

    const slide1 = slides[0];
    const slide2 = slides[1];

    const slide1ShouldDisplayHeatmaps =
      // Wait for the base slide to load before displaying heatmaps
      this.viewer1?.osd?.internalOSD?.world?.getItemCount() &&
      (!isEmpty(slide1?.heatmapResults?.internalResults) ||
        !isEmpty(slide1?.heatmapResults?.publishedResults) ||
        !isEmpty(slide1?.internalHeatmaps));

    const slide2ShouldDisplayHeatmaps =
      // Wait for the base slide to load before displaying heatmaps
      this.viewer2?.osd?.internalOSD?.world?.getItemCount() &&
      (!isEmpty(slide2?.heatmapResults?.internalResults) ||
        !isEmpty(slide2?.heatmapResults?.publishedResults) ||
        !isEmpty(slide2?.internalHeatmaps));

    return (
      <ColorModeContext.Consumer>
        {({ mode: _mode }) => (
          <div className="slides-viewer">
            <Viewer
              darkBackground={!isEmpty(slide1?.channelsMetadata)}
              slide={slide1}
              onZoomSlide={this.handleViewer1ZoomSlide}
              onPanSlide={this.handleViewer1PanSlide}
              onOpenSlide={this.preserveFieldOfView}
              ref={(viewer) => {
                this.viewer1 = viewer;
              }}
              isDisplayingTaggedSlide={!areAllSlidesFocused && focusedSlideId === slide1?.id}
            >
              {this.viewer1?.osd?.internalOSD && (
                <>
                  <SlideViewerOverlays
                    navigatorShown={showNavigation}
                    displaySlideId={displaySlideId}
                    viewerInternalOSD={this.viewer1.osd.internalOSD}
                    slide={slide1}
                  />

                  {!this.props.hideComments && (
                    <PinCommentsHandler
                      procedureId={this.props.procedureId}
                      slideId={slide1.id}
                      viewer={this.viewer1.osd.internalOSD}
                    />
                  )}
                </>
              )}
              <div className={classnames('navigator-container', { hide: !showNavigation })}>
                <div className="navigator" id="navigator1" />
              </div>
            </Viewer>
            {this.viewer1?.osd?.internalOSD && (slide1ShouldDisplayHeatmaps || !isEmpty(slide1.channels)) && (
              <Overlays slide={slide1} osdViewer={this.viewer1.osd.internalOSD} viewer={this.viewer1} />
            )}
            {slides.length === 2 && (
              <Viewer
                darkBackground={!isEmpty(slide2?.channelsMetadata)}
                slide={slide2}
                onZoomSlide={this.handleViewer2ZoomSlide}
                onPanSlide={this.handleViewer2PanSlide}
                onOpenSlide={this.preserveFieldOfView}
                ref={(viewer) => {
                  this.viewer2 = viewer;
                }}
                isDisplayingTaggedSlide={!areAllSlidesFocused && focusedSlideId === slide2.id}
              >
                {this.viewer2?.osd?.internalOSD && (
                  <>
                    <SlideViewerOverlays
                      navigatorShown={showNavigation}
                      displaySlideId={displaySlideId}
                      viewerInternalOSD={this.viewer2.osd.internalOSD}
                      slide={slide2}
                    />

                    <PinCommentsHandler
                      procedureId={this.props.procedureId}
                      slideId={slide2.id}
                      viewer={this.viewer2.osd.internalOSD}
                    />
                  </>
                )}
                <div className={classnames('navigator-container', { hide: !showNavigation })}>
                  <div className="navigator" id="navigator2" />
                </div>
              </Viewer>
            )}
            {this.viewer2?.osd?.internalOSD && (slide2ShouldDisplayHeatmaps || !isEmpty(slide2.channels)) && (
              <Overlays slide={slide2} osdViewer={this.viewer2.osd.internalOSD} viewer={this.viewer2} />
            )}
          </div>
        )}
      </ColorModeContext.Consumer>
    );
  }
}

interface SlideViewerOverlaysProps {
  navigatorShown: boolean;
  displaySlideId: boolean;
  viewerInternalOSD: OpenSeadragon.Viewer;
  slide: SlideWithChannelAndResults;
}

const SlideViewerOverlays = (props: SlideViewerOverlaysProps) => {
  useSignals();
  const { navigatorShown, displaySlideId, viewerInternalOSD, slide } = props;
  const slideId = slide.id;
  const heatmapLegends = getHeatmapLegends(slide);
  return (
    <div className={classnames('view-info-container')}>
      {displaySlideId && (
        <div className="slide-metadata">
          <span>{slideId}</span>
        </div>
      )}
      <Grid container style={{ position: 'absolute', bottom: 30 }}>
        {map(heatmapLegends, (legend, index) => (
          <Grid item key={index}>
            <Grow in>
              <Paper elevation={0} style={{ opacity: 0.95 }}>
                <ColorMapLegend variant={legend.colorMap} colorMapRange={legend.range} nshades={100} />
              </Paper>
            </Grow>
          </Grid>
        ))}
      </Grid>
      <div className={classnames('view-info-overlays')}>
        <MousePositionOverlay
          viewer={viewerInternalOSD}
          location={navigatorShown ? MouseOverlayLocation.bottom : MouseOverlayLocation.top}
        />

        <ScaleIndicator
          // @ts-ignore
          scalebarInstance={viewerInternalOSD.scalebarInstance}
        />
      </div>
    </div>
  );
};

export default SlidesViewer;
