import { Grid, Skeleton, Typography } from '@mui/material';
import { Registration } from 'interfaces/slide';
import { groupBy, map } from 'lodash';
import React from 'react';
import { RegistrationDetails, SlideRegistrationDetailsByOrchestration } from '../..';
import RegistrationOrchestration from './RegistrationOrchestration';

interface SlideRegistrationSectionProps {
  studyId: string;
  allRegistrations: Registration[];
  isLoading: boolean;
  isError: boolean;
  selectedRegistrations: SlideRegistrationDetailsByOrchestration;
  setSelectedRegistrations: (value: React.SetStateAction<SlideRegistrationDetailsByOrchestration>) => void;
}

const SlideRegistrationsSection: React.FC<React.PropsWithChildren<SlideRegistrationSectionProps>> = ({
  studyId,
  allRegistrations,
  isLoading,
  isError,
  selectedRegistrations,
  setSelectedRegistrations,
}) => {
  const registrationsByOrchId: SlideRegistrationByOrchestration = groupBy(allRegistrations, 'orchestrationId');

  return (
    <Grid item container spacing={1}>
      <Grid item>
        <Typography>Slide Registration Results:</Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <RegistrationsByOrchIdSkeleton />
        ) : isError ? (
          <Typography>Error loading slide registrations</Typography>
        ) : (
          <Grid container spacing={1} direction="column">
            {map(registrationsByOrchId, (registrations, orchestration) => {
              return (
                <Grid item key={orchestration}>
                  <RegistrationOrchestration
                    studyId={studyId}
                    orchestration={orchestration}
                    registrations={registrations}
                    selectedRegistrations={selectedRegistrations[orchestration] || ([] as RegistrationDetails[])}
                    onSelectSlidesRegistration={(newRegistrations) =>
                      setSelectedRegistrations((prevRegistration) => ({
                        ...prevRegistration,
                        [orchestration]: newRegistrations,
                      }))
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const RegistrationsByOrchIdSkeleton: React.FC = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Skeleton variant="rounded" width="100%" height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width="100%" height={40} />
        </Grid>
      </Grid>
    </Grid>
  );
};

type SlideRegistrationByOrchestration = {
  [orchestrationId: string]: Registration[];
};

export default SlideRegistrationsSection;
