import { map } from 'lodash';

import { AnnotationAssignment, TodoDefinition } from 'interfaces/annotation';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
import { CasesParams } from 'utils/useCasesParams';

export const getAnnotationAssignments = (queryParams: string) => {
  return apiRequestHandlerPromise<AnnotationAssignment[]>({
    url: `annotation_assignments?${queryParams}`,
    method: 'GET',
  });
};

export const getAllAnnotationAssignments = async (): Promise<AnnotationAssignment[]> => {
  return map(
    await apiRequestHandlerPromise<AnnotationAssignment[]>({
      url: `annotation_assignments/all`,
      method: 'GET',
    }),
    (assignment) => ({
      ...assignment,
      id: assignment.annotationAssignmentId,
    })
  );
};

export const getUnarchivedAnnotationAssignments = async (): Promise<AnnotationAssignment[]> => {
  return map(
    await apiRequestHandlerPromise<AnnotationAssignment[]>({
      url: `annotation_assignments/all_unarchived`,
      method: 'GET',
    }),
    (assignment) => ({
      ...assignment,
      id: assignment.annotationAssignmentId,
    })
  );
};

export const getTodoTypes = async (): Promise<TodoDefinition[]> => {
  return apiRequestHandlerPromise<TodoDefinition[]>({
    url: 'todo_types',
    method: 'GET',
  });
};

export const createAnnotationAssignment = async (data: Pick<AnnotationAssignment, 'name' | 'todos'> & CasesParams) => {
  return apiRequestHandlerPromise<AnnotationAssignment>({
    url: 'annotation_assignments',
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const addSlidesToAnnotationAssignment = async ({
  annotationAssignmentId,
  casesParams,
}: {
  annotationAssignmentId: number;
  casesParams: CasesParams;
}) => {
  return apiRequestHandlerPromise<void>({
    url: `annotation_assignments/${annotationAssignmentId}/slides`,
    method: 'POST',
    data: JSON.stringify(casesParams),
  });
};
